(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Phase
   *
   * @description
   *
   */
  angular
      .module('neo.services')
      .factory('InvoiceLines', InvoiceLines);

  function InvoiceLines($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/invoiceref/:Id/:invoiceid/:anyo', {id: '@_Id',invoiceid:'@_invoiceid',anyo:'@_anyo'}, {
     
      query: {
        method: 'GET',
        isArray: true
      }
    });
  }
}());